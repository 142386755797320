import Modal from "react-modal";
import { ResponsiveLine } from "@nivo/line";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickerStyle.css";
import {
  GraphContainer,
  GraphName,
  RoomHeading,
  H2,
  Grid,
  OuterBlock,
  InnerBlock,
  BodyBlock,
  BlockHeading,
  Heading,
  ParameterContainer,
  ParameterName,
  ParameterValueRow,
  ParameterValue,
  ParameterUnit,
  CalendarIconBox,
  CalendarIcon,
  DateRangePickerContainer,
} from "./styledComponents";
import { SpinnerContainer } from "pages/Home/Home";
import CalendarIconImage from "assets/img/calendarIcon.svg";
import { Spin } from "antd";

const ParameterBlock = ({ name, value, unit }: any) => {
  return (
    <>
      <ParameterContainer>
        <ParameterName>{name}</ParameterName>
        <ParameterValueRow>
          <ParameterValue>{value}</ParameterValue>
          <ParameterUnit>{unit}</ParameterUnit>
        </ParameterValueRow>
      </ParameterContainer>
    </>
  );
};

const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }: any) => {
  return (
    <DateRangePickerContainer>
      <CalendarIconBox>
        <CalendarIcon src={CalendarIconImage} />
      </CalendarIconBox>
      <DatePicker
        className="input-class-first"
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start date"
      />
      <DatePicker
        className="input-class-last"
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="Stop date"
      />
    </DateRangePickerContainer>
  );
};

Modal.setAppElement("#root");

const MyResponsiveLine = ({ data, unit }: any) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 10, bottom: 50, left: 60 }}
      xScale={{ format: "%Y-%m-%d %H:%M:%S", type: "time" }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      // yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
      // yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        format: "%H:%M",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -41,
        legend: "transportation",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      // axisBottom={null}
      axisLeft={{
        // orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: unit,
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      lineWidth={1}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      enableArea={true}
      areaOpacity={0.25}
      useMesh={true}
      colors={{ scheme: "nivo" }}
      legends={[]}
    />
  );
};

const customStyles: any = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "800px",
    height: "90vh",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "30px 40px",
    borderRadius: "10px",
    overflow: "scroll",
  },
  overlay: {
    background: "#00000090",
  },
};

const RoomModal = ({
  modalIsOpen,
  closeModal,
  selectedRoomNo,
  selectedDeviceId,
  startDate,
  handleSetStartDate,
  endDate,
  handleSetEndDate,
  graphData,
  graphStatus,
  roomData,
}: any) => {
  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <RoomHeading>
          <H2>DEVICE ID: {selectedRoomNo}</H2>
        </RoomHeading>
        <BodyBlock>
          <Grid>
            <Grid column={true} width={30}>
              {/* <OuterBlock>
                <InnerBlock>
                  <BlockHeading>
                    <Heading>INDOOR</Heading>
                    <ParameterBlock name={"Temperature"} value={roomData["temperature"]} unit={"C"} />
                    <ParameterBlock name={"Humidity"} value={roomData["humidity"]} unit={"%"} />
                    <ParameterBlock name={"CO2"} value={roomData["co2"]} unit={"ppm"} />
                  </BlockHeading>
                </InnerBlock>
              </OuterBlock> */}
              <OuterBlock>
                <InnerBlock>
                  <BlockHeading>
                    <Heading>DEVICE INFO</Heading>
                    <ParameterBlock name={"Name"} value={selectedDeviceId["deviceName"]} unit={""} />
                    <ParameterBlock name={"Status"} value={roomData["status"] ? "online" : "offline"} unit={""} />
                    <ParameterBlock name={"Device ID"} value={roomData["mac_address"]} unit={""} />
                    <ParameterBlock name={"Battery level"} value={roomData["battery_level"]} unit={""} />
                    {/* <ParameterBlock name={'Wi-Fi signal'} value={'25'} unit={''} /> */}
                  </BlockHeading>
                </InnerBlock>
              </OuterBlock>
            </Grid>
            <Grid column={true} width={70} style={{ marginTop: "-33px" }}>
              <DateRangePicker startDate={startDate} setStartDate={handleSetStartDate} endDate={endDate} setEndDate={handleSetEndDate} />
              {graphStatus ? (
                Object.keys(graphData).map((grahpName: string) => {
                  return (
                    <GraphContainer>
                      <GraphName>{graphData[grahpName]["title"]}</GraphName>
                      {graphData[grahpName]["data"].length !== 0 ? (
                        <>
                          <MyResponsiveLine data={graphData[grahpName]["data"]} unit={graphData[grahpName]["unit"]} />
                        </>
                      ) : (
                        <p>No data</p>
                      )}
                    </GraphContainer>
                  );
                })
              ) : (
                <>
                  <SpinnerContainer>
                    <Spin tip="Loading..." style={{ marginTop: "100px", fontSize: "22px", color: "#0c4271" }} />
                  </SpinnerContainer>
                </>
              )}
            </Grid>
          </Grid>
        </BodyBlock>
      </Modal>
    </>
  );
};
export default RoomModal;
