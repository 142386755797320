import ReactDOM from "react-dom";
import App from "./App";
import { FirebaseProvider } from "./firebase";
import GlobalStyle from "./GlobalStyle/GlobalStyles";
//@ts-ignore
import MessengerCustomerChat from "react-messenger-customer-chat";
import { PahoProvider } from "./paho";

ReactDOM.render(
  <FirebaseProvider>
    <App />
    <GlobalStyle />
    <MessengerCustomerChat pageId={"685983694926359"} appId={"306251554567859"} />
  </FirebaseProvider>,
  document.getElementById("root")
);
