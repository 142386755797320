import RoomStatusNew from "components/RoomStatusNew";
import { DeviceDataProps } from "./homeType";

export const RoomStatusBlock = ({ deviceData, deviceDataFirebase, openModal, userName, deviceDataCrate }: any) => {
  return (
    <>
      {deviceData &&
        deviceDataFirebase &&
        deviceData.map((device: DeviceDataProps) => {
          let checkDeviceObject: any = Object.values(deviceDataFirebase[device["device_id"]]);
          if (checkDeviceObject) {
            let x = device["ui_location"].split(",")[0];
            let y = device["ui_location"].split(",")[1];
            let deviceData = deviceDataFirebase[device["device_id"]];
            let tempValue =
              deviceData["environment"] && deviceData["environment"]["subdev_0"] ? deviceData["environment"]["subdev_0"]["temperature"] : " - ";
            let humidValue =
              deviceData["environment"] && deviceData["environment"]["subdev_0"]
                ? deviceData["environment"]["subdev_0"]["humidity"]
                  ? deviceData["environment"]["subdev_0"]["humidity_calibration"]
                    ? deviceData["environment"]["subdev_0"]["humidity"] + deviceData["environment"]["subdev_0"]["humidity_calibration"]
                    : deviceData["environment"]["subdev_0"]["humidity"]
                  : " - "
                : " - ";
            let co2Value = deviceData["environment"] && deviceData["environment"]["subdev_0"] ? deviceData["environment"]["subdev_0"]["co2"] : " - ";
            return (
              <RoomStatusNew
                key={device["device_id"]}
                x={x}
                y={y}
                roomName={device.room_name}
                displayName={userName === "sfhadmin" ? device.room_name : device.device_name.replace("netatmo ", "").replace(" ", "")}
                tempValue={tempValue}
                humidValue={humidValue}
                co2Value={co2Value}
                openModal={openModal}
                deviceId={device.device_id}
              />
            );
          } else {
            return null;
          }
        })}
    </>
  );
};
