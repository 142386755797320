import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BASE_URL;

class UserService {
  getDevices() {
    return axios.get(API_URL + "/iot/api/v2.0/devices", {
      headers: authHeader(),
    });
  }

  getDeviceHistory(starttime: any, endtime: any, device_id: any) {
    let url: string = `?start_time=${starttime}&end_time=${endtime}&device_id=${device_id}&source=local`;
    return axios.get("http://192.168.1.104:8000/api/v2.0/historydevice" + url, {
      headers: authHeader(),
    });
  }

  postAutomations(
    allowNotification: boolean,
    roomName: string,
    subDeviceName: string,
    parameter: string,
    comparator: string,
    value: string | number,
    active: boolean
  ) {
    let payload: any = {
      automation_image: "",
      automation_name: "automation_daikin",
      trigger: {
        trigger_device: {
          [roomName]: {
            [subDeviceName]: {
              [parameter]: {
                [comparator]: value,
              },
            },
          },
        },
      },
      condition: {
        condition_value: "",
        condition_event: "",
      },
      action: {},
      allow_notification: allowNotification ? active : false,
      notification_message: `${parameter} is higher than limit`,
    };

    return axios.post(
      API_URL + "api/v2.0/automations/",
      {
        headers: authHeader(),
      },
      payload
    );
  }
}

export default new UserService();
