import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Select from "react-select";
import { DateRangePickerContainer } from "components/RoomModal/styledComponents";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickerStyle.css";
import MUIDataTable from "mui-datatables";
import { Button } from "antd";
import UserService from "services/user.service";
import { Spin } from "antd";
import { useToasts } from "react-toast-notifications";
import CalendarIconImage from "assets/img/calendarIcon.svg";
import ReportIconImage from "assets/img/file.png";
import DeviceIconImage from "assets/img/devices.png";
import { FirebaseContext } from "../../firebase";
import { hospitalMapper } from "pages/Home/Home";

const tableOptions: any = {
  filterType: "checkbox",
};

type DropDownState = {
  isClearable: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  isRtl: boolean;
  isSearchable: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  overflow-y: scroll;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const ColumnName = styled.h3`
  font-size: 17px;
  margin-right: 30px;
  padding-top: 5px;
  width: 140px;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  bottom: -20px;
  left: 20px;
  z-index: 0;
`;

const options = [
  { value: "deviceHistory", label: "Device history" },
  // { value: 'notification', label: 'Notification history' },
];

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    display: "inline-block",
    width: "250px",
    height: "40px",
    minHeight: "1px",
    textAlign: "left",
    border: "none",
    filter: "drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.05))",
    zIndex: 9999,
  }),
};

const Icon = styled.img`
  width: 20px;
  margin-right: 10px;
  padding-bottom: 3px;
`;

const customStyles2 = {
  container: (provided: any) => ({
    ...provided,
    display: "inline-block",
    width: "250px",
    height: "40px",
    minHeight: "1px",
    textAlign: "left",
    border: "none",
    filter: "drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.05))",
    zIndex: 9998,
  }),
};

const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }: any) => {
  return (
    <DateRangePickerContainer style={{ justifyContent: "flex-start" }}>
      <DatePicker
        className="input-class-first"
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start date"
      />
      <DatePicker
        className="input-class-last"
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="Stop date"
      />
    </DateRangePickerContainer>
  );
};

export const Report = () => {
  const [dropdownState] = useState<DropDownState>({
    isClearable: false,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  });
  const { addToast } = useToasts();
  const firebase = useContext<any>(FirebaseContext);

  const [reportType, setReportType] = useState(options[0]);
  let today = new Date();
  let yesterday = new Date(today.setDate(today.getDate() - 1));
  const [reportStartDate, setReportStartDate] = useState<any>(yesterday);
  const [reportEndDate, setReportEndDate] = useState<Date>(new Date());
  const [deviceOptions, setDeviceOptions] = useState();
  const [selectedDeviceId, setSelectedDeviceId] = useState<any>();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [columnTable, setColumnTable] = useState<any>([]);

  const handleSetReportStartDate = (e: any) => {
    setReportStartDate(e);
  };

  const handleSetReportEndDate = (e: any) => {
    setReportEndDate(e);
  };

  const convertDate = (date: Date, time: string) => {
    const convertNumber = (number: number) => {
      if (number < 10) {
        return "0" + number;
      } else {
        return number;
      }
    };
    return date.getFullYear() + "-" + convertNumber(date.getMonth() + 1) + "-" + convertNumber(date.getDate()) + " " + time;
  };

  const addCalibration = (data: any, calibration?: number) => {
    setTableData(
      data.map((item: any) => ({
        ...item,
        ["humidity"]: item["humidity"] + calibration,
      }))
    );
  };

  const requestReport = () => {
    if (reportType["value"] === "deviceHistory") {
      if (selectedDeviceId) {
        let humidityCalibration = 0;
        setIsLoadingReport(true);
        if (firebase) {
          let username: any = sessionStorage.getItem("username");
          const roomStatus = `/hospital/${hospitalMapper[username]}/building_main/${selectedDeviceId["room"]}/iot_devices/${selectedDeviceId["deviceId"]}`;
          firebase.db.ref(roomStatus).once("value", function (snap: any) {
            let capt = snap.val();
            if (selectedDeviceId["deviceId"] && capt && capt.environment.subdev_1.humidity_calibration) {
              humidityCalibration = capt.environment.subdev_1.humidity_calibration;
            }

            UserService.getDeviceHistory(convertDate(reportStartDate, "00:00"), convertDate(reportEndDate, "23:30"), selectedDeviceId["deviceId"])
              .then((res: any) => {
                setIsLoadingReport(false);
                addCalibration(res.data.results, humidityCalibration);
                setColumnTable(
                  Object.keys(res.data.results[0]).map((key: string) => {
                    return {
                      name: key,
                      label: key,
                      options: {
                        filter: true,
                        sort: true,
                      },
                    };
                  })
                );
              })
              .catch(() => {
                setIsLoadingReport(false);
                addToast("Cannot get device history", { appearance: "error", autoDismiss: true });
              });
          });
        }
      } else {
        addToast("Please complete the form", { appearance: "error", autoDismiss: true });
      }
    } else if (reportType["value"] === "notification") {
      addToast("Cannot get notification history", { appearance: "error", autoDismiss: true });
      // UserService.getDeviceHistory(reportStartDate, reportEndDate).then((res) => {
      //   console.log(res.data);
      // });
    }
  };

  useEffect(() => {
    UserService.getDevices()
      .then((res) => {
        let options = res.data.devices.map((deviceInfo: any) => {
          return {
            label: `(${deviceInfo["room_name"]}) ` + deviceInfo["device_name"],
            value: deviceInfo["device_name"],
            room: deviceInfo["room_name"],
            deviceId: deviceInfo["device_id"],
          };
        });
        setDeviceOptions(options);
        setSelectedDeviceId(options[0]);
      })
      .catch(() => {
        addToast("Cannot get devices data", { appearance: "error", autoDismiss: true });
      });
  }, []);

  return (
    <>
      <Container>
        <SearchContainer>
          <Column>
            <ColumnName>
              <Icon src={ReportIconImage} alt="calendar" /> Report type
            </ColumnName>
            <Select
              styles={customStyles}
              defaultValue={reportType}
              isDisabled={dropdownState.isDisabled}
              isLoading={dropdownState.isLoading}
              isClearable={dropdownState.isClearable}
              isRtl={dropdownState.isRtl}
              isSearchable={dropdownState.isSearchable}
              name="color"
              options={options}
              onChange={(e: any) => setReportType(e)}
            />
          </Column>
          {reportType["value"] === "deviceHistory" && (
            <Column>
              <ColumnName>
                <Icon src={DeviceIconImage} alt="calendar" /> Device name
              </ColumnName>
              {!deviceOptions ? (
                <Spin style={{ paddingTop: "8px" }} />
              ) : (
                <Select
                  styles={customStyles2}
                  isDisabled={dropdownState.isDisabled}
                  isLoading={dropdownState.isLoading}
                  isClearable={dropdownState.isClearable}
                  isRtl={dropdownState.isRtl}
                  isSearchable={dropdownState.isSearchable}
                  name="color"
                  options={deviceOptions}
                  value={selectedDeviceId}
                  onChange={(e: any) => setSelectedDeviceId(e)}
                />
              )}
            </Column>
          )}
          <Column>
            <ColumnName>
              <Icon src={CalendarIconImage} alt="calendar" /> Period
            </ColumnName>
            <DateRangePicker
              startDate={reportStartDate}
              setStartDate={handleSetReportStartDate}
              endDate={reportEndDate}
              setEndDate={handleSetReportEndDate}
            />
          </Column>
          <Column>
            <SaveButtonContainer>
              <Button type="primary" onClick={() => requestReport()} disabled={isLoadingReport}>
                Search
              </Button>
            </SaveButtonContainer>
          </Column>
        </SearchContainer>
        <PreviewContainer>
          <MUIDataTable title={reportType["label"]} data={tableData} columns={columnTable} options={tableOptions} />
        </PreviewContainer>
      </Container>
    </>
  );
};
