import { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import ProfileImage from "assets/common/profileimage.svg";
import LogOutImage from "assets/common/logout.svg";

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  width: 210px;
  margin-top: 10px;
  @media only screen and (max-width: 1201px) {
    margin-bottom: 15px;
  }
`;

const Block = styled.div<any>`
  position: relative;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  background: url(${(props) => props.src}) center no-repeat;
  background-size: contain;
  font-size: 20px;
  color: white;
  text-decoration: uppercase;
`;

const ProfileName = styled.p`
  font-size: 16px;
  text-align: start;
  margin: 0px;
  width: 100%;
  color: #397cdd;
  font-weight: 600;
`;

const RoleName = styled.p`
  font-size: 16px;
  text-align: start;
  margin: 0px;
  width: 100%;
  color: #397cdd;
`;

const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 50px;
  right: 0px;
  width: 150px;
  height: fit-content;
  padding: 5px 15px 10px 15px;
  background-color: white;
  border-radius: 5px;
`;

const DropdownRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 10px 0px;
  :hover {
    opacity: 0.8;
  }
`;

const TransparentBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
`;

const AnimatedDropdownContainer = animated(DropdownContainer);

type ProfleDropdownProps = {
  open: boolean;
};

const ProfileDropdown = ({ open }: ProfleDropdownProps) => {
  const dropdownProps = useSpring({
    opacity: open ? "1" : "0",
    display: open ? "block" : "none",
  });

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  };

  return (
    <>
      <AnimatedDropdownContainer style={dropdownProps}>
        <DropdownRow onClick={() => (window.location.href = "/admin/myprofile")}>My profile</DropdownRow>
        <DropdownRow onClick={() => handleLogout()}>Logout</DropdownRow>
      </AnimatedDropdownContainer>
    </>
  );
};

const Profile = ({ onClick }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <TransparentBg onClick={() => setOpen(!open)} />}
      <Container>
        <Block src={ProfileImage} onClick={() => setOpen(!open)} style={{ width: "50px" }}>
          {sessionStorage.getItem("hotelCode")?.toUpperCase().substring(0, 2)}
        </Block>
        <Block style={{ flexDirection: "column", marginLeft: "0px", width: "90px", paddingLeft: "6px" }}>
          <ProfileName>{sessionStorage.getItem("username") === "anandaadmin" ? "Ananda" : sessionStorage.getItem("username")}</ProfileName>
          <RoleName>{sessionStorage.getItem("role") === "md" && "ADMIN"}</RoleName>
        </Block>
        <Block style={{ paddingLeft: "30px" }}>
          <img width={25} src={LogOutImage} alt="lout" onClick={onClick} style={{ cursor: "pointer" }} />
        </Block>
      </Container>
    </>
  );
};

export default Profile;
